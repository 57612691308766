import {ReactNode} from "react";
import {Button} from "../../button";
import {Modal} from "../Modal";
import {ModalContent} from "../ModalContent";
import {ModalFooter} from "../ModalFooter";
import {ModalHeader} from "../ModalHeader";
import s from "./NotificationModal.module.css";

type Props = {
    title: string;
    positive: string;
    negative?: string;
    isSwapButtonColors?: boolean;
    onPositive: () => void;
    onNegative?: () => void;
    onDismiss?: () => void;
    children: ReactNode;
};

export const NotificationModal = (props: Props) => {
    const {title, positive, negative, isSwapButtonColors, onPositive, onDismiss, onNegative, children} = props;

    return (
        <Modal width={580} onDismiss={onDismiss || onNegative || onPositive}>
            <ModalHeader title={title}/>
            <ModalContent className={s.content}>
                {children}
            </ModalContent>
            <ModalFooter
                positive={
                    <Button color={isSwapButtonColors ? "Warning" : "Primary"} autoFocus={!negative}
                            onClick={onPositive}>
                        {positive}
                    </Button>
                }
                negative={negative && (
                    <Button color={isSwapButtonColors ? "Primary" : "Warning"} autoFocus={!!negative}
                            onClick={onNegative}>
                        {negative}
                    </Button>
                )}
            />
        </Modal>
    );
};
