import React from "react";
import IconCheckmark10x8 from "../../components/icons/IconCheckmark10x8";
import {CheckboxBase, CheckboxBaseProps, CheckboxVariant} from "./CheckboxBase";

type Props = {
    value: boolean;
    variant?: CheckboxVariant;
    onToggle?: (value: boolean) => void;
} & CheckboxBaseProps;

export const Checkbox = (props: Props) => {
    const {value, variant, onToggle, ...defaultProps} = props;

    const handleValueChange = () => onToggle?.(!value);
    return (
        <CheckboxBase
            value={value}
            variant={variant}
            icon={value && <IconCheckmark10x8/>}
            backgroundColor={null}
            onToggle={handleValueChange}
            {...defaultProps} />
    );
};
