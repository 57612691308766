export interface CitizenHouse {
    id: string;
    address: string;
}

export interface CitizenHouseResponse {
    house_fias_id: string;
    address_standard: string;
}

export function mapToCitizenHouse(response: CitizenHouseResponse): CitizenHouse {
    return {
        id: response.house_fias_id,
        address: response.address_standard,
    };
}
