import {makeAutoObservable} from "mobx";
import {useMemo} from "react";
import {useRepositories} from "../components/app/RepositoriesStore";
import {ItemLimitRepository} from "../repositories";

export const PAGE_LIMITS = [25, 50, 100];

export class ItemLimitStore {
    value: number;

    constructor(
        private readonly key: string,
        private readonly limitRepo: ItemLimitRepository,
    ) {
        this.value = this.invalidate(limitRepo.find(key));
        makeAutoObservable(this, {}, {autoBind: true});
    }

    setLimit(value: number) {
        this.value = value;
        this.limitRepo.save(this.key, value);
    }

    private invalidate(value: number | null) {
        if (value && PAGE_LIMITS.includes(value)) {
            return value;
        } else {
            return PAGE_LIMITS[0];
        }
    }
}

export function useItemLimitStoreMemo(key: string) {
    const repos = useRepositories();
    return useMemo(() => new ItemLimitStore(key, repos.itemLimit), [key, repos]);
}
