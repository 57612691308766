import classNames from "classnames";
import {ComponentProps} from 'react';
import s from "./Avatar.module.css";
import {AvatarSkeleton} from "./AvatarSkeleton";

type Props = {
    size: number;
    src: string;
} & Omit<ComponentProps<"img">, "alt" | "width" | "height" | "style">;

export const Avatar = (props: Props) => {
    const {className, size, ...defaultProps} = props;
    return (
        <img
            className={classNames(s.avatar, className)}
            style={{width: size, height: size}}
            alt=""
            {...defaultProps}/>
    );
};

Avatar.Skeleton = AvatarSkeleton;