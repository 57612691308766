import {FlowCancellationError, makeAutoObservable} from "mobx";
import {RefreshTokenError} from "../../net";

// noinspection JSUnusedGlobalSymbols
export enum JobErrorStrategy {
    /**
     * При возникновении ошибки, работа останавливается, и обработчик переходит в состояние JobState.ERROR
     */
    CANCEL,

    /**
     * При возникновении ошибки игнорируются, таймер обработчика перезапускается
     */
    IGNORE
}

export enum JobState {
    /**
     * Только что создан или остановлен
     */
    SUSPENDED,

    /**
     * Запущен, но работа ещё не выполнялась до конца
     */
    RUN,

    /**
     * Запущен, работа выполнилась хотябы один раз
     */
    PENDING,

    /**
     * Произошла ошибка. Можен возникнуть, только если установлена стратегия обработки ошибок JobErrorStrategy.CANCEL
     */
    ERROR
}

export interface RepeatableJobOptions {
    job: AsyncFunc | MobXFlow | MobXFlowUndecorated;
    errorStrategy?: JobErrorStrategy,
    delay: number;
}

export class RepeatableAsyncJob {
    //Options
    private readonly _job: MobXFlow | AsyncFunc;
    private readonly _errorStrategy: JobErrorStrategy;
    private readonly _delay: number;

    //State
    private _timeoutId: number;
    private _pendingPromise: Promise<void> | CancellablePromise | null;
    private prevVisibilityState: string;
    private hideTime: number | null;
    private readonly visibilityListener: () => void;

    constructor(options: RepeatableJobOptions) {
        this._job = options.job as AsyncFunc;
        this._errorStrategy = options.errorStrategy ?? JobErrorStrategy.CANCEL;
        this._delay = options.delay;
        this._error = null;
        this._isCompletedAtLeastOnce = false;

        this._state = JobState.SUSPENDED;
        this._timeoutId = -1;
        this._pendingPromise = null;
        this.hideTime = null;
        this.prevVisibilityState = document.visibilityState;
        this.visibilityListener = () => {
            if (document.visibilityState !== this.prevVisibilityState) {
                this.prevVisibilityState = document.visibilityState;
                if (document.visibilityState === "hidden") {
                    if (this.isWorking) {
                        this.hideTime = Date.now();
                        if (this.isWorking && this._timeoutId !== -1) {
                            clearTimeout(this._timeoutId);
                            this._timeoutId = -1;
                        }
                    }
                } else if (this.isWorking) {
                    if (this.hideTime === null || Date.now() - this.hideTime > this._delay) {
                        this.executeTimeout();
                    } else {
                        this.startTimeout();
                    }
                }
            }
        };
        makeAutoObservable(this, {}, {autoBind: true});
    }

    private _isCompletedAtLeastOnce: boolean;

    get isCompletedAtLeastOnce() {
        return this._isCompletedAtLeastOnce;
    }

    private _error: Error | null;

    get error() {
        return this._error;
    }

    private _state: JobState;

    get state() {
        return this._state;
    }

    get isWorking() {
        return this._state === JobState.RUN || this._state === JobState.PENDING;
    }

    get isSuspended() {
        return this._state === JobState.SUSPENDED;
    }

    get isRun() {
        return this._state === JobState.RUN;
    }

    get isPending() {
        return this._state === JobState.PENDING;
    }

    get isLoading() {
        return this._state === JobState.SUSPENDED || this._state === JobState.RUN;
    }

    get errorMessage() {
        return this._error?.message;
    }

    clearError() {
        this._error = null;
    }

    start() {
        this._start(false);
    }

    startQuietly() {
        this._start(true);
    }

    call() {
        if (!this.isWorking) {
            return;
        }

        this.cancelBackgroundJob();
        this.executeTimeout();
    }

    resetIfNeed() {
        if (!this.isWorking) {
            return;
        }

        this.reset();
    }

    reset() {
        this.cancelBackgroundJob();

        this._state = JobState.RUN;
        this._error = null;
        this.executeTimeout();
    }

    stop() {
        this.cancelBackgroundJob();
        this._state = JobState.SUSPENDED;
        document.removeEventListener("visibilitychange", this.visibilityListener);
    }

    private _start(isQuietly: boolean) {
        if (this.isWorking) {
            this.reset();
            return;
        }

        this._state = isQuietly ? JobState.PENDING : JobState.RUN
        this._error = null;
        this.executeTimeout();
        document.addEventListener("visibilitychange", this.visibilityListener);
    }

    private executeTimeout() {
        if (this.prevVisibilityState === "hidden") {
            return;
        }

        this._pendingPromise = this._job();
        this._pendingPromise
            .then(this.startTimeout)
            .catch(async e => {
                if (e instanceof FlowCancellationError) {
                    console.error(e);
                    return;
                }

                if (e instanceof RefreshTokenError) {
                    console.error(e);
                    return;
                }

                if (this._errorStrategy === JobErrorStrategy.CANCEL) {
                    this.cancelBackgroundJob();
                    this._state = JobState.ERROR;
                    this._error = e as Error;
                    document.removeEventListener("visibilitychange", this.visibilityListener);
                } else {
                    this.startTimeout();
                }

                console.error(e);
            });
    }

    private cancelPromise() {
        const pendingAsCancellable = this._pendingPromise as CancellablePromise | null;
        if (pendingAsCancellable?.cancel) {
            pendingAsCancellable.cancel();
        }

        this._pendingPromise = null;
    }

    private cancelTimer() {
        if (this._timeoutId > -1) {
            clearTimeout(this._timeoutId);
        }

        this._timeoutId = -1;
    }

    private cancelBackgroundJob() {
        if (this.isSuspended) {
            return;
        }

        this.cancelPromise();
        this.cancelTimer();
    }

    private startTimeout() {
        this._isCompletedAtLeastOnce = true;
        this._state = JobState.PENDING;
        this._timeoutId = setTimeout(this.executeTimeout, this._delay) as unknown as number;
    }
}
