export enum CitizenApplicationState {
    NEW = 1,
    DELEGATED,
    ACCEPTED,
    REJECTED,
}

export interface CitizenApplication {
    id: string;
    address: string;
    flat: string;
    firstName: string;
    middleName: string;
    secondName: string;
    fullName: string;
    phone: string;
    email: string;
    state: CitizenApplicationState;
    owners: unknown[];
    possibleStates: CitizenApplicationState[];
    type: "account_deletion" | "flat_registration";
    isAddressCorrect: boolean;
    createdAt: Date;
}

export interface CitizenApplicationResponse {
    request_id: string;
    address: string;
    flat: string;
    first_name: string;
    middle_name: string;
    second_name: string;
    phone: string;
    email: string;
    state: number;
    owners: unknown[];
    possible_statuses: number[];
    address_is_correct: boolean;
    type: "account_deletion" | "flat_registration";
    created_at: string;
}

export type FindAllCitizenApplicationParams = {
    page: number;
    limit: number;
    search?: string;
    status?: "new" | "delegated" | "delete" | "finished",
};

export function mapCitizenApplication(response: CitizenApplicationResponse): CitizenApplication {
    return {
        id: response.request_id,
        address: response.address,
        flat: response.flat,
        firstName: response.first_name,
        secondName: response.second_name,
        middleName: response.middle_name,
        fullName: `${response.second_name} ${response.first_name} ${response.middle_name}`.trim(),
        phone: response.phone,
        email: response.email,
        state: response.state,
        owners: response.owners,
        type: response.type,
        possibleStates: response.possible_statuses,
        isAddressCorrect: response.address_is_correct,
        createdAt: new Date(response.created_at),
    };
}
