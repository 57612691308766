import {useEffect, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";

export function useQueryString(key: string, initial: string = "") {
    return useMemo(() => {
        //Не используем useSearchParams, чтобы состояние десериализовалось только один раз (при загрузке страницы)
        const query = new URLSearchParams(window.location.search);
        return query.get(key) || initial
    }, [key, initial]);
}

export function useQueryNumber(key: string, initial: number = 0) {
    return useMemo(() => {
        //Не используем useSearchParams, чтобы состояние десериализовалось только один раз (при загрузке страницы)
        const query = new URLSearchParams(window.location.search);
        const value = query.get(key);
        if (value === null) {
            return initial;
        } else return parseInt(value) || initial;
    }, [key, initial]);
}

export function useQueryPreserve<V>(key: string, value: V) {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const params = searchParams;
        if (!value) {
            if (params.has(key)) {
                params.delete(key);
                setSearchParams(params, {replace: true});
            }
            return;
        }

        if (typeof value === "string") {
            if (params.get(key) !== value) {
                params.set(key, value);
                setSearchParams(params, {replace: true});
            }
            return;
        }

        const serialized = JSON.stringify(value);
        if (params.get(key) !== serialized) {
            params.set(key, serialized);
            setSearchParams(params, {replace: true});
        }
    }, [key, value, searchParams, setSearchParams]);
}

export function useQueryState<T>(key: string, defaultValue: T) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = useState(searchParams.get(key) || defaultValue);

    useEffect(() => {
        setSearchParams((searchParams) => {
            if (value) {
                searchParams.set(key, value.toString());
            } else {
                searchParams.delete(key);
            }
            return searchParams;
        }, {replace: true});
    }, [key, value, setSearchParams]);

    return [value as T, setValue] as const;
}
