import {setLocale} from "yup";

/* eslint-disable no-template-curly-in-string */
setLocale({
    mixed: {
        required: "Заполните поле",
    },
    string: {
        min: "Минимальная длина - ${min} символов",
        max: "Максимальная длина - ${max} символов",
        length: "Длина поля - ${length} символов",
        email: "Не верный адрес",
        matches: "Недопустимое значение"
    },
});