import {action, makeAutoObservable, runInAction} from "mobx";
import {TaskDumpState} from "tasks/types";
import {TriState} from "../components/checkbox";
import {AsyncJob, RepeatableAsyncJob} from "./job";

type Params = {
    providePageIds: () => number[];
    fetchDump: (mode: "inclusion" | "exclusion", ids: number[]) => Promise<void>;
    fetchDumpState: () => Promise<TaskDumpState>;
    fetchCancelDump: () => Promise<void>;
}

export class DumperStore {
    pendingDump: TaskDumpState | null;

    private isSelectedAll: boolean;
    private selectedIds: number[];
    private isAlreadyFetched: boolean;
    private readonly providePageTaskIds: Params["providePageIds"];
    private readonly fetchDump: Params["fetchDump"];
    private readonly fetchDumpState: Params["fetchDumpState"];
    private readonly fetchCancelDump: Params["fetchCancelDump"];
    private readonly fetchPendingJob: RepeatableAsyncJob;
    private readonly generateDumpJob: AsyncJob<typeof DumperStore.prototype._generateDump>;
    private readonly cancelDumpJob: AsyncJob<typeof DumperStore.prototype._cancelDump>;

    constructor(params: Params) {
        this.selectedIds = [];
        this.isSelectedAll = false;
        this.isAlreadyFetched = false;
        this.pendingDump = null;
        this.providePageTaskIds = action(params.providePageIds);
        this.fetchDump = params.fetchDump;
        this.fetchDumpState = params.fetchDumpState;
        this.fetchCancelDump = params.fetchCancelDump;
        makeAutoObservable(this, {}, {autoBind: true});

        this.fetchPendingJob = new RepeatableAsyncJob({
            job: this._fetchPendingDump,
            delay: 2000,
        });
        this.generateDumpJob = new AsyncJob({job: this._generateDump});
        this.cancelDumpJob = new AsyncJob({job: this._cancelDump});
    }

    get isLoading() {
        return !this.isAlreadyFetched || this.generateDumpJob.isPending;
    }

    get canSelectAll() {
        return this.providePageTaskIds().length > 0;
    }

    get modalError() {
        return this.fetchPendingJob.errorMessage || this.generateDumpJob.errorMessage || this.cancelDumpJob.errorMessage;
    }

    get canGenerateDump() {
        return this.isSelectedAll || this.selectedIds.length > 0;
    }

    get isDumpInProgressOrCompleted() {
        return this.pendingDump?.status === "pending" || this.pendingDump?.status === "completed";
    }

    /**
     * Состояние выбора элементов на странице:
     * - {@link TriState.Checked} - выделены все элементы на странице.
     * - {@link TriState.Indeterminate} - элементы выделены частично (как минимум один).
     * - {@link TriState.Unchecked} - не выбран ни один элемент.
     */
    get pageSelectionState(): TriState {
        const pageIds = this.providePageTaskIds();

        const isAllSelected = pageIds.every(id => {
            const isSelected = this.selectedIds.includes(id);
            return this.isSelectedAll ? !isSelected : isSelected;
        });
        if (isAllSelected) {
            return TriState.Checked;
        }

        const isSomeSelected = pageIds.some(id => {
            const isSelected = this.selectedIds.includes(id);
            return this.isSelectedAll ? !isSelected : isSelected;
        });
        if (isSomeSelected) {
            return TriState.Indeterminate;
        }

        return TriState.Unchecked;
    }

    toggleAllSelection() {
        if (this.isDumpInProgressOrCompleted) {
            return;
        }

        if (this.isSelectedAll && this.selectedIds.length > 0) {
            this.selectedIds = [];
        } else {
            this.isSelectedAll = !this.isSelectedAll;
            this.selectedIds = [];
        }
    }

    togglePageSelection() {
        if (this.isDumpInProgressOrCompleted) {
            return;
        }

        const pageIds = this.providePageTaskIds();
        const isAllSelected = pageIds.every(task => this.selectedIds.includes(task));
        if (isAllSelected) {
            this.selectedIds = this.selectedIds.filter(taskId => !pageIds.includes(taskId));
        } else {
            for (const taskId of pageIds) {
                if (!this.selectedIds.includes(taskId)) {
                    this.selectedIds.push(taskId);
                }
            }
        }
    }

    toggleSelection(id: number) {
        if (this.isDumpInProgressOrCompleted) {
            return;
        }

        const index = this.selectedIds.indexOf(id);
        if (index > -1) {
            this.selectedIds.splice(index, 1);
        } else {
            this.selectedIds.push(id);
        }
    }

    isSelected(id: number) {
        if (this.isSelectedAll) {
            return !this.selectedIds.includes(id);
        }

        return this.selectedIds.includes(id);
    }

    generateDump() {
        this.generateDumpJob.start();
    }

    downloadDump() {
        const pendingDump = this.pendingDump;
        if (pendingDump?.status !== "completed") {
            return;
        }

        const url = pendingDump.url;
        if (url) {
            window.open(url, "_blank");
        }
        this.pendingDump = null;
        this.resetSelection();
        this.generateDumpJob.stop();
    }

    dismissDump() {
        this.pendingDump = null;
        this.resetSelection();
        this.fetchPendingJob.stop();
        this.generateDumpJob.stop();
        this.cancelDumpJob.start();
    }

    countSelected(totalCount: number) {
        return this.isSelectedAll ? totalCount - this.selectedIds.length : this.selectedIds.length;
    }

    reset() {
        this.pendingDump = null;
        this.resetSelection();
        this.fetchPendingJob.stop();
        this.generateDumpJob.stop();
        this.cancelDumpJob.stop();
    }

    clearError() {
        this.fetchPendingJob.clearError();
        this.generateDumpJob.clearError();
        this.cancelDumpJob.clearError();
    }

    private resetSelection() {
        this.isSelectedAll = false;
        this.selectedIds = [];
    }

    private* _generateDump() {
        yield this.fetchDump(this.isSelectedAll ? "exclusion" : "inclusion", this.selectedIds);

        this.pendingDump = {
            status: "pending",
            url: null,
        }
        this.resetSelection();
        this.isAlreadyFetched = true;
        this.fetchPendingJob.start();
    }

    private* _fetchPendingDump() {
        const state: TaskDumpState = yield this.fetchDumpState();

        if (state.status === "pending") {
            this.pendingDump = state;
        }

        if (state.status === "completed") {
            setTimeout(() => runInAction(() => this.fetchPendingJob.stop()), 1);
            this.pendingDump = state;
        }

        if (state.status === "none" && !this.isAlreadyFetched) {
            setTimeout(() => runInAction(() => this.fetchPendingJob.stop()), 1);
            this.pendingDump = null;
        }

        this.isAlreadyFetched = true;
    }

    private* _cancelDump() {
        yield this.fetchCancelDump();
    }
}
