import {AuthorizedHttpClient} from "common/net";
import {CitizenPageableResponse, mapCitizenPageable, Pageable} from "common/types";
import {
    CitizenApplication,
    CitizenApplicationResponse,
    FindAllCitizenApplicationParams,
    mapCitizenApplication
} from "../types";

export class CitizenApplicationRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(params: FindAllCitizenApplicationParams): Promise<Pageable<CitizenApplication>> {
        const response = await this.client.get<CitizenPageableResponse<CitizenApplicationResponse>>(
            "citizens/applications", {
                params: {
                    size: params.limit,
                    page: params.page + 1,
                    search: params.search,
                    status: params.status,
                }
            },
        );
        return mapCitizenPageable(response, mapCitizenApplication);
    }

    patchAddressById(id: string, houseId: string): Promise<void> {
        return this.client.patch(`citizens/applications/${id}`, {
            body: {
                house_fias_id: houseId,
            },
        });
    }

    acceptRegistrationById(id: string): Promise<void> {
        return this.client.post(`citizens/applications/${id}`);
    }

    acceptDeletionById(id: string): Promise<void> {
        return this.client.post(`citizens/remove/${id}`);
    }

    rejectRegistrationById(id: string): Promise<void> {
        return this.client.delete(`citizens/applications/${id}`);
    }

    rejectDeletionById(id: string): Promise<void> {
        return this.client.delete(`citizens/remove/${id}`);
    }
}
