import {AuthorizedHttpClient} from "common/net";
import {mapPageable, Pageable, PageableResponse} from "common/types";
import {Citizen, CitizenResponse, CitizenUpdateParams, FindAllCitizenParams, mapToCitizen} from "../types";

export class CitizenRepository implements CitizenRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAllByHouseId(houseId: string, params: FindAllCitizenParams): Promise<Pageable<Citizen>> {
        const response = await this.client.get<PageableResponse<CitizenResponse>>(`/houses/${houseId}/citizens`, {
            params: {
                search: params.search,
                status: params.status,
                page: params.page + 1,
                limit: params.limit,
            },
        });
        return mapPageable(response, mapToCitizen);
    }

    async findById(id: string): Promise<Citizen> {
        const response = await this.client.get<CitizenResponse>(`citizens/${id}`);
        return mapToCitizen(response);
    }

    putById(id: string, params: CitizenUpdateParams): Promise<void> {
        return this.client.put(`citizens/${id}`, {
            body: {
                first_name: params.firstName,
                second_name: params.secondName,
                middle_name: params.middleName,
                phone: params.phone,
                email: params.email,
                is_make_owner: params.isMakeOwner,
            },
        });
    }

    lockById(id: string): Promise<void> {
        return this.client.delete(`citizens/${id}`);
    }

    unlockById(id: string): Promise<void> {
        return this.client.patch(`citizens/${id}`);
    }
}
