import {UserStore} from "common/stores";
import {makeAutoObservable} from "mobx";
import {DefinedUserRole} from "users/types";

export class TaskPermissions {
    private userStore: UserStore | null;

    constructor() {
        this.userStore = null;
        makeAutoObservable(this, {}, {autoBind: true});
    }

    /**
     * Определяет, может ли авторизированный пользователь модифицировать (добавлять, переименовывать, удалять) список
     * программ для связанных заявок.
     *
     * @see RelatedTask
     * @see RelatedTaskRepository
     */
    get canModifyPrograms() {
        const roleId = this.requireUserStore().roleId;
        return [
            DefinedUserRole.USER,
            DefinedUserRole.DISPATCHER,
            DefinedUserRole.ADMIN,
            DefinedUserRole.ADMIN_ETD
        ].includes(roleId);
    }

    /**
     * Определяет, может ли авторизированный пользователь редактировать поля заявки: планируемая дата завершения, связанные
     * заявки, и т.п. (за исключением статуса).
     */
    get canEditTask() {
        const roleId = this.requireUserStore().roleId;
        return [
            DefinedUserRole.ADMIN_ETD,
            DefinedUserRole.ADMIN,
            DefinedUserRole.DISPATCHER,
            DefinedUserRole.USER,
        ].includes(roleId);
    }

    /**
     * Определяет, может ли авторизированный пользователь настраивать делегирование заявок для компаний.
     *
     * @see DelegationSetting
     * @see DelegationSettingRepository
     */
    get canSettingDelegation() {
        const roleId = this.requireUserStore().roleId;
        return roleId === DefinedUserRole.ADMIN_ETD;
    }

    /**
     * Определяет, может ли авторизированный пользователь писать комментарии к завершенным заявкам.
     */
    get canSendCommentsForFinished() {
        return this.requireUserStore().roleId === DefinedUserRole.DISPATCHER;
    }

    //Класс UserStore инициализируется и передается с помощью контекста в компоненте GlobalLoadingScreen, а данный класс
    //инициализируется в RepositoriesStore. Этот метод - костыль, потому что нельзя получить UserStore из RepositoriesStore.
    setUserStore(userStore: UserStore) {
        this.userStore = userStore;
    }

    private requireUserStore(): UserStore {
        if (this.userStore === null) {
            throw new Error("UserStore is not set");
        }

        return this.userStore;
    }
}
