import classNames from "classnames";
import React, {HTMLAttributes, ReactNode} from "react";
import {P} from "../typography";
import s from "./Checkbox.module.css";

export type CheckboxVariant = "default" | "listed";

const classByVariant: Record<CheckboxVariant, string> = {
    "default": s.variantDefault,
    "listed": s.variantListed,
}

type Props = {
    value: boolean;
    variant?: CheckboxVariant;
    icon: ReactNode;
    backgroundColor: string | null;
    onToggle?: () => void;
} & CheckboxBaseProps;

export type CheckboxBaseProps = {
    label?: string;
    isDisabled?: boolean;
    isDarkerBackground?: boolean;
} & Omit<HTMLAttributes<HTMLLabelElement>, "value">;

export const CheckboxBase = (props: Props) => {
    const {
        className,
        variant = "default",
        value,
        icon,
        label,
        backgroundColor,
        isDisabled,
        isDarkerBackground,
        onToggle,
        ...defaultProps
    } = props;

    const background = backgroundColor || (isDarkerBackground ? "var(--checkbox-active-background-darker)" : "var(--checkbox-active-background)");
    return (
        <label className={classNames(classByVariant[variant], s.labelContainer, className)} {...defaultProps}>
            <input
                className={s.realCheckbox}
                type="checkbox"
                checked={value}
                disabled={isDisabled}
                onChange={onToggle}/>

            <span
                className={value ? s.checkboxChecked : s.checkbox}
                style={{backgroundColor: value ? background : undefined}}>
                {icon}
            </span>

            {label && (
                <P variant="Paragraph/Small/p" color={isDisabled ? "dark-gray-main" : undefined}>
                    {label}
                </P>
            )}
        </label>
    );
};
