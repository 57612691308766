export interface Citizen {
    id: string;
    userId: string;
    fullName: string;
    firstName: string;
    middleName: string;
    secondName: string;
    type: "owner" | "citizen" | "guest";
    apartment: string;
    entrance: string;
    phone: string;
    email: string;
    accessPeriod: {
        from: Date,
        to: Date,
    } | null,
    status: "active" | "blocked";
}

export interface CitizenUpdateParams {
    firstName: string;
    middleName: string;
    secondName: string;
    phone: string;
    email: string;
    isMakeOwner: boolean;
}

export interface CitizenResponse {
    id: string;
    user_id: string;
    full_name: string;
    first_name: string;
    middle_name: string;
    second_name: string;
    type: "owner" | "citizen" | "guest";
    apartment: string;
    entrance: string;
    phone: string;
    email: string;
    access_period: {
        from: string,
        to: string,
    } | null,
    status: "active" | "blocked";
}

export type FindAllCitizenParams = {
    limit: number;
    page: number;
    status?: "active" | "blocked";
    search: string;
};

export function mapToCitizen(citizen: CitizenResponse): Citizen {
    return {
        id: citizen.id,
        userId: citizen.user_id,
        fullName: citizen.full_name,
        firstName: citizen.first_name,
        secondName: citizen.second_name,
        middleName: citizen.middle_name,
        phone: citizen.phone,
        status: citizen.status,
        type: citizen.type,
        entrance: citizen.entrance,
        email: citizen.email,
        apartment: citizen.apartment,
        accessPeriod: citizen.access_period ? {
            from: new Date(citizen.access_period.from),
            to: new Date(citizen.access_period.to),
        } : null,
    };
}
