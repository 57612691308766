import {AuthorizedHttpClient} from "common/net";
import {CitizenPageableResponse, mapCitizenPageable, Pageable} from "common/types";
import {CitizenHouse, CitizenHouseResponse, mapToCitizenHouse} from "../types";

export class CitizenHouseRepository {
    constructor(private readonly client: AuthorizedHttpClient) {
    }

    async findAll(search: string, signal?: AbortSignal): Promise<Pageable<CitizenHouse>> {
        const response = await this.client.get<CitizenPageableResponse<CitizenHouseResponse>>(
            "houses", {
                signal,
                params: {
                    address_standard__like: search,
                },
            },
        );

        return mapCitizenPageable(response, mapToCitizenHouse);
    }
}
